.App {
  text-align: center;
}

.App-logo {
  height: 100px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #282c34;
  color: white;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// generate scss for basic bootstrap form-group
form {
  width: 100%;
}

.form-group {
  display: flex;
  width: 100%;
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
}

.button,
button {
  display: inline-block;
  vertical-align: middle;
  flex: 0 0 auto;
  padding: 0.375rem 0.75rem;
  border: 1px solid #dc3545;
  border-radius: 20px;
  background-color: #dc3545;
  color: #fff;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    border-color: #dae0e5;
    background-color: #e2e6ea;
    color: #212529;
  }
}

button,
.button,
input[type="email"] {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

input[type="email"] {
  flex: 1 1 auto;
  width: 100%;
  max-width: 600px;
  margin: 0;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 20px 0 0 20px;
  background-color: #fff;
  color: #495057;

  & + button,
  & + .button {
    border-radius: 0 20px 20px 0;
  }
}

.success {
  width: 100%;
  max-width: 600px;
  margin: 0 0 20px 0;
  padding: 20px;
  border: 1px solid #c3e6cb;
  border-radius: 20px;
  background-color: #d4edda;
  color: #155724;
  font-size: 18px;
}
